import React, { useState, useEffect } from "react";
import {
  Box,
  IconButton,
  Button,
  Input,
  Tabs,
  Tab,
  InputLabel,
  Typography,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import CloseIcon from "@mui/icons-material/Close";
import EditOffIcon from "@mui/icons-material/EditOff";
import Panorama from "../Panorama/Panorama";
import ImgDropdownMenu from "../Panorama/Spots/Hotspots/ImgDropdownMenu.js";
import {
  useDeleteHotspotMutation,
  useDeleteInfospotMutation,
  useAddHotspotMutation,
  useAddInfospotMutation,
  useUploadToCloudinaryMutation,
} from "../../api";
import toast from "react-hot-toast";
import ImageUploading from "react-images-uploading";
import { useChangeTitle, useChangeInfo } from "../Panorama/Handlers.js";

const EditControls = ({
  editState,
  setEditState,
  toggleClass,
  toggleHotspot,
  toggleInfospot,
  post,
  store,
  setStoreChangesLog,
  setStore,
  id,
  which,
  activeSpotToEdit,
  selectedPano,
  setSelectedPano,
}) => {
  const [images, setImages] = useState(null);
  const [activeTab, setActiveTab] = useState(0);
  const [showPopup, setShowPopup] = useState(false); // State for controlling popup
  const [feedbackMessage, setFeedbackMessage] = useState(null); // State for feedback message
  const [feedbackType, setFeedbackType] = useState("");

  // Function to close popup when clicking outside or pressing X
  const closePopup = () => setShowPopup(false);

  const [addHotspot, { isLoading: isAddingHotspot }] = useAddHotspotMutation();
  const [addInfospot, { isLoading: isAddingInfospot }] =
    useAddInfospotMutation();
  const [deleteHotspot, { isLoading: isDeletingHotspot }] =
    useDeleteHotspotMutation();
  const [deleteInfospot, { isLoading: isDeletingInfospot }] =
    useDeleteInfospotMutation();
  const [
    uploadToCloudinary,
    { isLoading: isUploading, data: uploadResponse, error: uploadError },
  ] = useUploadToCloudinaryMutation();

  const changeInfo = useChangeInfo(which, store, setStore, setStoreChangesLog);
  const changeTitle = useChangeTitle(store, setStore, setStoreChangesLog);

  const displayFeedback = (message, type) => {
    setFeedbackMessage(message);
    setFeedbackType(type);
    setTimeout(() => {
      setFeedbackMessage(null); // Clear the message after 3 seconds
    }, 3000);
  };

  useEffect(() => {
    if (images) {
      newPano(images, which, activeSpotToEdit);
    }
  }, [images]);

  function newPano(imageList, imageKey, hotspotKey, addUpdateIndex) {
    const img = new Image();
    img.src = imageList[0].data_url;
    img.onload = () => {
      var w = img.width;
      var h = img.height;
      if (w === 2 * h) {
        var random1 = Math.random().toString(36).substring(2, 12);
        var random2 = Math.random().toString(36).substring(2, 12);
        var randPanoName = random1 + random2 + "-" + imageList[0].file.name;

        const newPanoObject = {
          name: [],
          color: "lightblue",
          position: [],
          panoName: imageList[0].file.name,
          randPanoName: randPanoName,
          url: imageList[0].data_url,
          link: [],
        };
        console.log(newPanoObject);
        setStore((prevStore) => [...prevStore, newPanoObject]);
        console.log(id);
        handleUploadToCloudinary(id, newPanoObject);

        setStoreChangesLog((prevLog) => [
          ...prevLog,
          { type: "hotspot", imageKey: imageKey, hotspotKey: hotspotKey },
        ]);

        setStore((prevStore) => {
          const updatedStore = [...prevStore];

          if (
            updatedStore[imageKey].name.length ===
            updatedStore[imageKey].link.length
          ) {
            updatedStore[imageKey].link[hotspotKey] = updatedStore.length - 1;
          }

          return updatedStore;
        });
      } else {
        toast.error("Image must be a panorama, where width = 2 x height.");
        setImages("");
      }
    };
  }

  const handleSelectedPano = (e) => {
    setSelectedPano(e.target.value);
  };

  const handleAddHotspot = async (id, imageKey, newPanoObject) => {
    try {
      const response = await addHotspot({
        id,
        imageKey,
        newPanoObject,
      }).unwrap();
    } catch (error) {
      console.error("Failed to add hotspot:", error);
    }
  };

  const handleAddInfospot = async (id, imageKey, newPanoObject) => {
    try {
      const response = await addInfospot({
        id,
        imageKey,
        newPanoObject,
      }).unwrap();
    } catch (error) {
      console.error("Failed to add infospot:", error);
    }
  };

  const handleDeleteHotspot = async (id, which, index) => {
    try {
      await deleteHotspot({ id, which, index }).unwrap();
    } catch (error) {
      console.error("Failed to delete hotspot:", error);
    }
  };

  const handleDeleteInfospot = async (id, which, index) => {
    try {
      await deleteInfospot({ id, which, index }).unwrap();
    } catch (error) {
      console.error("Failed to delete infospot:", error);
    }
  };

  const handleUploadToCloudinary = async (id, newPanoObject) => {
    try {
      console.log(id);
      console.log(newPanoObject);
      const response = await uploadToCloudinary({ id, newPanoObject }).unwrap();
    } catch (error) {
      console.error("Failed to upload to Cloudinary:", error);
    }
  };

  const onImgChange = (imageList, addUpdateIndex) => {
    const isDuplicate = store.some(
      (item) => item.panoName === imageList[0].file.name
    );
    if (isDuplicate) {
      toast.error("This image has already been uploaded.");
    } else {
      setImages(imageList);
    }
  };

  function saveSpot(panoIndex, hpIndex, selectedPano) {
    var newPanoObject = store[panoIndex];

    if (editState.hotspotActive) {
      store[panoIndex].link[hpIndex] = selectedPano;
      handleAddHotspot(id, panoIndex, newPanoObject);
      displayFeedback("Hotspot successfully saved.", "success");
    } else if (editState.infospotActive) {
      handleAddInfospot(id, panoIndex, newPanoObject);
      displayFeedback("Infospot successfully saved.", "success");
    }
  }

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
    // Toggle the edit state based on the selected tab
    if (newValue === 0) {
      toggleHotspot();
    } else {
      toggleInfospot();
    }
  };

  const handleToggleEditMenu = () => {
    toggleClass();

    if (activeTab == 0) {
      // Update state conditionally based on the current value of isActive
      setEditState((prevState) => {
        if (prevState.isActive) {
          return {
            ...prevState,
            hotspotActive: true, //hotspot
          };
        }
        // If isActive is false, just return the previous state
        return prevState;
      });
    } else {
      // Update state conditionally based on the current value of isActive
      setEditState((prevState) => {
        if (prevState.isActive) {
          return {
            ...prevState,
            infospotActive: true, //infospot
          };
        }
        // If isActive is false, just return the previous state
        return prevState;
      });
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        position: "absolute",
        top: 0,
        left: 0,
        p: 1,
        gap: 2,
        zIndex: 10,
        color: "white",
        backgroundColor: editState.isActive
          ? "rgba(51, 51, 51, 0.8)"
          : "rgba(51, 51, 51, 0.6)",
        borderRadius: editState.isActive ? "0px" : "0px 0px 8px 0px",
        boxShadow: "0 4px 12px rgba(0, 0, 0, 0.15)",
        fontSize: "14px",
        fontWeight: 500,
        cursor: "pointer",
        width: editState.isActive ? { xs: "50vw", md: "30vw" } : "50px", // 45vw for small screens, 30vw for medium and up
        height: editState.isActive ? "100%" : "57px", // Full height only when editing
        alignItems: "flex-start",
        transition: "all 0.3s ease",
      }}
    >
      <Box
        sx={{
          display: editState.isActive ? "flex" : "none",
          flexDirection: "column",
          flexGrow: 1,
          width: "100%", // Ensure the child container takes up the full width
        }}
      >
        <Tabs
          value={activeTab}
          onChange={handleTabChange}
          sx={{
            borderBottom: "1px solid rgba(255, 255, 255, 0.2)",
            width: "100%", // Tabs should also take full width
            ".MuiTab-root": {
              color: "#aaa",
              fontWeight: "bold",
              "&.Mui-selected": { color: "#fff" },
            },
            ".MuiTabs-indicator": { backgroundColor: "#fff" },
          }}
        >
          <Tab label="Hotspot" />
          <Tab label="Infospot" />
        </Tabs>

        {activeTab === 0 && editState.hotspotActive && (
          <>
            <ImageUploading
              multiple={false}
              value={images}
              onChange={onImgChange}
              maxNumber={100}
              dataURLKey="data_url"
            >
              {({ imageList, onImageUpload, isDragging, dragProps }) => (
                <div
                  className="upload__image-wrapper"
                  style={{ width: "100%" }}
                >
                  <Button
                    type="primary"
                    style={isDragging ? { color: "#bbb" } : { color: "#fff" }}
                    onClick={onImageUpload}
                    {...dragProps}
                    sx={{
                      my: 1,
                      color: "#fff",
                      backgroundColor: "#1a73e8",
                      "&:hover": { backgroundColor: "#1765c2" },

                      borderRadius: "6px",
                      textTransform: "none",
                      width: "100%", // Ensure the button takes up full width
                    }}
                  >
                    Upload a new image
                  </Button>
                </div>
              )}
            </ImageUploading>

            <ImgDropdownMenu
              store={store}
              selectedPano={selectedPano}
              handleSelectedPano={handleSelectedPano}
              sx={{
                width: "100%", // Ensure dropdown takes full width
              }}
            />

            <Box
              sx={{
                height: "120px", // Control the height of the container
                maxHeight: "15vh", // Make it responsive if needed
                width: "100%", // Full width
                overflow: "hidden", // Prevent the content from overflowingk
              }}
            >
              <Panorama store={store} which={selectedPano} />
            </Box>
            <InputLabel sx={{ fontSize: "14px", color: "white", mt: 2 }}>
              Change Hotspot Title
            </InputLabel>
            <Input
              placeholder={store[which].name[activeSpotToEdit]}
              inputProps={{ maxLength: 16 }}
              onChange={(e) =>
                changeTitle(which, activeSpotToEdit, e.target.value)
              }
              sx={{
                borderRadius: "4px",
                backgroundColor: "#444",
                color: "#fff",
                padding: "8px",
                border: "none",
                outline: "none",
                width: "100%", // Input should take full width
                "&:focus": {
                  backgroundColor: "#555",
                },
              }}
            />
            {/* Container for Save and Delete buttons */}
            <Box sx={{ display: "flex", gap: 1, mt: 2, width: "100%" }}>
              <Button
                onClick={() => saveSpot(which, activeSpotToEdit, selectedPano)}
                variant="contained"
                color="primary"
                sx={{
                  backgroundColor: "#1a73e8",
                  "&:hover": { backgroundColor: "#1765c2" },
                  padding: "8px 16px",
                  borderRadius: "6px",
                  textTransform: "none",
                  width: "100%", // Button should take full width
                }}
              >
                Save
              </Button>

              <Button
                onClick={() => {
                  var temp = [...store];
                  temp[which].link.splice(activeSpotToEdit, 1);
                  temp[which].name.splice(activeSpotToEdit, 1);
                  temp[which].position.splice(activeSpotToEdit, 1);
                  setStore(temp);
                  handleDeleteHotspot(id, which, activeSpotToEdit);
                }}
                variant="contained"
                color="error"
                sx={{
                  "&:hover": {
                    borderColor: "#d32f2f",
                    backgroundColor: "rgba(229, 57, 53, 0.7)",
                  },
                  padding: "8px 16px",
                  borderRadius: "6px",
                  textTransform: "none",
                  width: "100%", // Button should take full width
                }}
              >
                Delete
              </Button>
            </Box>
            {/* Feedback Message */}
            {feedbackMessage && (
              <Box
                sx={{
                  mt: 2,
                  padding: "10px",
                  backgroundColor:
                    feedbackType === "success"
                      ? "rgba(76, 175, 80, 0.8)"
                      : "rgba(229, 57, 53, 0.8)",
                  borderRadius: "6px",
                  textAlign: "center",
                  color: "white",
                  fontWeight: "bold",
                }}
              >
                {feedbackMessage}
              </Box>
            )}
            {/* Need Help Button */}
            <Button
              onClick={() => setShowPopup(true)} // Show the popup
              sx={{
                mt: 2,
                color: "white",
                textTransform: "none",
                padding: "8px 16px",
                backgroundColor: "rgba(255, 255, 255, 0.1)",
                "&:hover": { backgroundColor: "rgba(255, 255, 255, 0.2)" },
                borderRadius: "6px",
                fontSize: "16px",
                width: "100%",
              }}
            >
              Need help?
            </Button>

            {/* Popup */}
            {showPopup && (
              <div
                style={{
                  position: "fixed",
                  top: 0,
                  left: 0,
                  width: "100vw",
                  height: "100vh",
                  backgroundColor: "rgba(0, 0, 0, 0.5)",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  zIndex: 1000,
                }}
                onClick={closePopup} // Close the popup when clicking outside
              >
                <div
                  style={{
                    backgroundColor: "#fff",
                    borderRadius: "10px",
                    padding: "20px",
                    width: "400px",
                    position: "relative",
                  }}
                  onClick={(e) => e.stopPropagation()} // Prevent closing when clicking inside the popup
                >
                  <IconButton
                    onClick={closePopup} // Close the popup when clicking the X button
                    style={{
                      position: "absolute",
                      top: "10px",
                      right: "10px",
                      color: "#000",
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                  <Typography variant="h6" sx={{ mb: 2 }}>
                    How to Add a Hotspot
                  </Typography>
                  <Typography variant="body2" sx={{ color: "black" }}>
                    <ul>
                      <li>Click anywhere to add the new hotspot location</li>
                      <li>
                        Click on the newly added or an existing hotspot to edit
                        it
                      </li>
                      <li>
                        Exit out of Hotspot editing to get to the image hotspot
                        is pointing to
                      </li>
                      <li>
                        If the hotspot has no selected image, it will be removed
                        when out of editing mode
                      </li>
                    </ul>
                  </Typography>
                </div>
              </div>
            )}
          </>
        )}

        {activeTab === 1 && editState.infospotActive && (
          <>
            <Input
              className="info-input"
              type="text"
              placeholder="Change Infospot text"
              inputProps={{ maxLength: 180 }}
              onChange={(e) =>
                changeInfo(which, activeSpotToEdit, e.target.value)
              }
              sx={{
                mt: 2,
                borderRadius: "4px",
                backgroundColor: "#444",
                color: "#fff",
                padding: "8px",
                border: "none",
                outline: "none",
                width: "100%", // Input should take full width
                "&:focus": {
                  backgroundColor: "#555",
                },
              }}
            />

            {/* Container for Save and Delete buttons */}
            <Box sx={{ display: "flex", gap: 1, mt: 2, width: "100%" }}>
              <Button
                onClick={() => saveSpot(which, activeSpotToEdit, selectedPano)}
                variant="contained"
                color="primary"
                sx={{
                  backgroundColor: "#1a73e8",
                  "&:hover": { backgroundColor: "#1765c2" },
                  padding: "8px 16px",
                  borderRadius: "6px",
                  textTransform: "none",
                  width: "100%", // Button should take full width
                }}
              >
                Save
              </Button>

              <Button
                onClick={() => {
                  var temp = [...store];
                  temp[which].infospotInfo.splice(activeSpotToEdit, 1);
                  temp[which].infospot.splice(activeSpotToEdit, 1);
                  setStore(temp);
                  handleDeleteInfospot(id, which, activeSpotToEdit);
                }}
                variant="contained"
                color="error"
                sx={{
                  "&:hover": {
                    borderColor: "#d32f2f",
                    backgroundColor: "rgba(229, 57, 53, 0.7)",
                  },
                  padding: "8px 16px",
                  borderRadius: "6px",
                  textTransform: "none",
                  width: "100%", // Button should take full width
                }}
              >
                Delete
              </Button>
            </Box>
            {/* Feedback Message */}
            {feedbackMessage && (
              <Box
                sx={{
                  mt: 2,
                  padding: "10px",
                  backgroundColor:
                    feedbackType === "success"
                      ? "rgba(76, 175, 80, 0.8)"
                      : "rgba(229, 57, 53, 0.8)",
                  borderRadius: "6px",
                  textAlign: "center",
                  color: "white",
                  fontWeight: "bold",
                }}
              >
                {feedbackMessage}
              </Box>
            )}
            {/* Need Help Button */}
            <Button
              onClick={() => setShowPopup(true)} // Show the popup
              sx={{
                mt: 2,
                color: "white",
                textTransform: "none",
                padding: "8px 16px",
                backgroundColor: "rgba(255, 255, 255, 0.1)",
                "&:hover": { backgroundColor: "rgba(255, 255, 255, 0.2)" },
                borderRadius: "6px",
                fontSize: "16px",
                width: "100%",
              }}
            >
              Need help?
            </Button>

            {/* Popup */}
            {showPopup && (
              <div
                style={{
                  position: "fixed",
                  top: 0,
                  left: 0,
                  width: "100vw",
                  height: "100vh",
                  backgroundColor: "rgba(0, 0, 0, 0.5)",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  zIndex: 1000,
                }}
                onClick={closePopup} // Close the popup when clicking outside
              >
                <div
                  style={{
                    backgroundColor: "#fff",
                    borderRadius: "10px",
                    padding: "20px",
                    width: "400px",
                    position: "relative",
                  }}
                  onClick={(e) => e.stopPropagation()} // Prevent closing when clicking inside the popup
                >
                  <IconButton
                    onClick={closePopup} // Close the popup when clicking the X button
                    style={{
                      position: "absolute",
                      top: "10px",
                      right: "10px",
                      color: "#000",
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                  <Typography variant="h6" sx={{ mb: 2 }}>
                    How to Add a Hotspot
                  </Typography>
                  <Typography variant="body2" sx={{ color: "black" }}>
                    <ul>
                      <li>Click anywhere to add the new infospot location</li>
                      <li>
                        Click on the newly added or an existing infospot to edit
                        it
                      </li>
                      <li>Exit out of Infospot editing to view the infospot</li>
                    </ul>
                  </Typography>
                </div>
              </div>
            )}
          </>
        )}

        {/* Invisible state markers */}
        <div
          className={
            editState.hotspotActive ? "hotspot-active" : "hotspot-inactive"
          }
        />
        <div
          className={
            editState.infospotActive ? "infospot-active" : "infospot-inactive"
          }
        />
      </Box>

      <Box sx={{ display: "flex", alignItems: "flex-start" }}>
        <IconButton
          onClick={handleToggleEditMenu}
          sx={{
            color: "white",
            "&:hover": {
              backgroundColor: "rgba(255, 255, 255, 0.1)",
            },
          }}
        >
          {editState.isActive ? <EditOffIcon /> : <EditIcon />}
        </IconButton>
      </Box>
    </Box>
  );
};

export default EditControls;
