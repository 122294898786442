import React, { Suspense } from "react";
import { Canvas } from "@react-three/fiber";
import { Html, OrbitControls, Loader } from "@react-three/drei";
import "antd/dist/antd.css";
import "./Panorama.css";
import Portals from "./Portals";

function Panorama({
  rotate,
  store,
  setStore,
  post,
  id,
  storeChangesLog,
  setStoreChangesLog,
  checkForEmptySpots,
  which,
  setWhich,
  displaySpots,
  makeSpotActiveToEdit,
  activeSpotToEdit,
  editState,
  setActiveSpotToEdit,
  selectedPano,
}) {
  const isRestricted = post?.option === "panorama";

  return (
    <Canvas frameloop="demand" style={{ height: "100%", width: "100%" }}>
      <OrbitControls
        minPolarAngle={isRestricted ? Math.PI / 2 : undefined}
        maxPolarAngle={isRestricted ? Math.PI / 2 : undefined}
        enableZoom={false}
        zoomSpeed={4}
        enablePan={true}
        enableDamping
        dampingFactor={0.2}
        autoRotate={rotate}
        autoRotateSpeed={0.5}
      />
      <Suspense
        fallback={
          <Html>
            <Loader />
          </Html>
        }
      >
        <Portals
          store={store}
          setStore={setStore}
          post={post}
          id={id}
          storeChangesLog={storeChangesLog}
          setStoreChangesLog={setStoreChangesLog}
          checkForEmptySpots={checkForEmptySpots}
          which={which}
          setWhich={setWhich}
          displaySpots={displaySpots}
          makeSpotActiveToEdit={makeSpotActiveToEdit}
          activeSpotToEdit={activeSpotToEdit}
          editState={editState}
          setActiveSpotToEdit={setActiveSpotToEdit}
          selectedPano={selectedPano}
        />
      </Suspense>
    </Canvas>
  );
}

export default Panorama;
