import React, { useState, useEffect, useRef } from "react";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { Box } from "@mui/material";
import MapIcon from "@mui/icons-material/Map";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import FullscreenExitIcon from "@mui/icons-material/FullscreenExit";
import ShareIcon from "@mui/icons-material/Share";
import toast from "react-hot-toast";

const fullscreenStyles = {
  position: "fixed",
  top: 0,
  left: 0,
  width: "100vw",
  height: "100vh",
  zIndex: 9999,
  backgroundColor: "black",
  overflow: "hidden",
};

const ViewControls = ({
  setDisplaySpots,
  displaySpots,
  panoramaRef,
  showFullscreenButton = true,
  post,
}) => {
  const [mapVisible, setMapVisible] = useState(false);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const link = `https://www.panostack.com/posts/showcase/${post._id}`;

  // fullscreen workaround for iPhones
  function isiPhone() {
    return /iPhone/.test(navigator.userAgent) && !window.MSStream;
  }

  function toggleDisplaySpots() {
    setDisplaySpots(!displaySpots);
  }

  function handleFullscreen() {
    const element = panoramaRef.current;
    const isIphone = isiPhone();

    if (isIphone) {
      // fullscreen workaround for iPhones
      if (element.classList.contains("fullscreen")) {
        element.classList.remove("fullscreen");
        element.style = "";
        setIsFullscreen(false);
      } else {
        element.classList.add("fullscreen");
        Object.assign(element.style, fullscreenStyles);
        setIsFullscreen(true);
      }
    } else {
      // Handle standard fullscreen API for other devices
      if (!document.fullscreenElement && !document.webkitFullscreenElement) {
        if (element.requestFullscreen) {
          element.requestFullscreen(); // Standard method
        } else if (element.mozRequestFullScreen) {
          element.mozRequestFullScreen(); // Firefox
        } else if (element.webkitRequestFullscreen) {
          element.webkitRequestFullscreen(); // Safari & Chrome
        } else if (element.msRequestFullscreen) {
          element.msRequestFullscreen(); // IE/Edge
        } else {
          console.error("Fullscreen API is not supported.");
        }
        setIsFullscreen(true);
      } else {
        if (document.exitFullscreen) {
          document.exitFullscreen(); // Standard method
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen(); // Firefox
        } else if (document.webkitExitFullscreen) {
          document.webkitExitFullscreen(); // Safari & Chrome
        } else if (document.msExitFullscreen) {
          document.msExitFullscreen(); // IE/Edge
        } else {
          console.error("Exit fullscreen API is not supported.");
        }
        setIsFullscreen(false);
      }
    }
  }

  const checkFullscreenStatus = () => {
    setIsFullscreen(document.fullscreenElement != null);
  };

  useEffect(() => {
    document.addEventListener("fullscreenchange", checkFullscreenStatus);
    return () => {
      document.removeEventListener("fullscreenchange", checkFullscreenStatus);
    };
  }, []);

  const copyLinkToClipboard = () => {
    const copylink = link;
    navigator.clipboard
      .writeText(copylink)
      .then(() => {
        toast.success("Share link copied to clipboard!");
      })
      .catch((err) => {
        toast.error("Could not copy to clipboard");
      });
  };

  return (
    <>
      {showFullscreenButton && (
        <Box
          onClick={handleFullscreen}
          sx={{
            position: "absolute",
            top: 0,
            right: 0,
            width: "30px",
            height: "30px",
            zIndex: 100,
            backgroundColor: "rgba(255, 255, 255, 0.3)",
            borderRadius: "4px",
            cursor: "pointer",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {isFullscreen ? (
            <IconButton color="inherit">
              <FullscreenExitIcon />
            </IconButton>
          ) : (
            <IconButton color="inherit">
              <FullscreenIcon />
            </IconButton>
          )}
        </Box>
      )}
      <Box
        onClick={toggleDisplaySpots}
        sx={{
          position: "absolute",
          top: "35px",
          right: 0,
          width: "30px",
          height: "30px",
          zIndex: 100,
          backgroundColor: "rgba(255, 255, 255, 0.3)",
          borderRadius: "4px",
          cursor: "pointer",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {displaySpots ? <VisibilityOffIcon /> : <VisibilityIcon />}
      </Box>
      <Box
        onClick={() => copyLinkToClipboard()}
        sx={{
          position: "absolute",
          top: "70px",
          right: 0,
          width: "30px",
          height: "30px",
          zIndex: 100,
          backgroundColor: "rgba(255, 255, 255, 0.3)",
          borderRadius: "4px",
          cursor: "pointer",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <ShareIcon />
      </Box>
      {post?.postType !== "single-image" && (
        <Box
          onClick={() => setMapVisible(!mapVisible)}
          sx={{
            position: "absolute",
            top: "105px",
            right: 0,
            width: "30px",
            height: "30px",
            zIndex: 100,
            backgroundColor: "rgba(255, 255, 255, 0.3)",
            borderRadius: "4px",
            cursor: "pointer",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <MapIcon />
        </Box>
      )}
      {mapVisible && (
        <Box
          sx={{
            backgroundColor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            p: 2,
            position: "absolute",
            top: "140px",
            right: 0,
            zIndex: 100,
          }}
        >
          {post.map && post.map !== "" ? (
            <img
              src={post.map}
              alt="Map Preview"
              style={{ maxWidth: "40vw", maxHeight: "45vh" }}
            />
          ) : (
            <div
              style={{ maxWidth: "40vw", maxHeight: "45vh", fontSize: "20px" }}
            >
              No Map/Floor Plan to Display
            </div>
          )}
          <IconButton aria-label="close" onClick={() => setMapVisible(false)}>
            <CloseIcon />
          </IconButton>
        </Box>
      )}
    </>
  );
};

export default ViewControls;
