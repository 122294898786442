import React, { useState, useEffect } from "react";
import * as THREE from "three";
import Hotspots from "./Spots/Hotspots/Hotspots";
import Infospots from "./Spots/Infospots/Infospots";
import { addSpot } from "./Handlers";
import { useMapsLoader } from "./Hooks";

function Portals({
  store,
  setStore,
  post,
  id,
  storeChangesLog,
  setStoreChangesLog,
  checkForEmptySpots,
  which,
  setWhich,
  displaySpots,
  makeSpotActiveToEdit,
  activeSpotToEdit,
  editState,
  setActiveSpotToEdit,
  selectedPano,
}) {
  const [isActive, setActive] = useState(false);
  const [isHPloading, setHPloading] = useState([]);
  const [isISloading, setISloading] = useState([]);

  useEffect(() => {
    if (checkForEmptySpots) {
      checkForEmptySpots("infospotInfo", "");
      checkForEmptySpots("link", "not-linked");
    }
  }, []);

  function handleAddSpotOnClick(e) {
    if (editState) {
      handleAddSpot(which, e.point);
    }
  }

  function handleAddSpot(which, location) {
    addSpot(
      which,
      location,
      store,
      setStore,
      storeChangesLog,
      setStoreChangesLog
    );
    if (store[which].link[store[which].link.length - 1] === "not-linked") {
      setActiveSpotToEdit(store[which].link.length - 1);
    } else if (
      store[which].infospotInfo[store[which].infospotInfo.length - 1] === ""
    ) {
      setActiveSpotToEdit(store[which].infospotInfo.length - 1);
    }
  }

  const { link } = store[which];
  const maps = useMapsLoader(store);
  const hps = store[which].position;
  const ifps = store[which].infospot;
  const name = store[which].name;
  const info = store[which].infospotInfo;

  let hotspotArray = [...store[which].link];
  let linkLength = hotspotArray.length - 1;
  if (hotspotArray[linkLength] === "not-linked") {
    hotspotArray[linkLength] = 0;
  }

  return (
    <group>
      <mesh onClick={handleAddSpotOnClick}>
        <sphereBufferGeometry args={[500, 60, 40]} />
        <meshBasicMaterial map={maps[which]} side={THREE.BackSide} />
      </mesh>

      <Hotspots
        displaySpots={displaySpots}
        hps={hps}
        isHPloading={isHPloading}
        store={store}
        setStore={setStore}
        which={which}
        id={id}
        setStoreChangesLog={setStoreChangesLog}
        setActive={setActive}
        hotspotArray={hotspotArray}
        name={name}
        link={link}
        storeChangesLog={storeChangesLog}
        post={post}
        isActive={isActive}
        setWhich={setWhich}
        makeSpotActiveToEdit={makeSpotActiveToEdit}
        activeSpotToEdit={activeSpotToEdit}
        editState={editState}
        selectedPano={selectedPano}
      />

      <Infospots
        displaySpots={displaySpots}
        ifps={ifps}
        isISloading={isISloading}
        store={store}
        setStore={setStore}
        which={which}
        id={id}
        setStoreChangesLog={setStoreChangesLog}
        setActive={setActive}
        info={info}
        storeChangesLog={storeChangesLog}
        post={post}
        isActive={isActive}
        makeSpotActiveToEdit={makeSpotActiveToEdit}
        activeSpotToEdit={activeSpotToEdit}
        editState={editState}
      />
    </group>
  );
}

export default Portals;
