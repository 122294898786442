import React, { useState, useRef, useEffect } from "react";
import ImgSlideShow from "../Panorama/ImgSlideShow";
import Panorama from "../Panorama/Panorama";
import PanoramicVideo from "../Panorama/PanoramicVideo";
import { useUpdatePostMutation } from "../../api";
import EditControls from "./EditControls";
import ViewControls from "./ViewControls";

const PostContent = ({ post, store, setStore, id }) => {
  const [storeChangesLog, setStoreChangesLog] = useState([]);
  const [which, setWhich] = useState(0);
  const [displaySpots, setDisplaySpots] = useState(true);
  const [activeSpotToEdit, setActiveSpotToEdit] = useState(null);
  const [selectedPano, setSelectedPano] = useState(0);
  const panoramaRef = useRef(null);
  const user = JSON.parse(localStorage.getItem("profile"));
  const isCurrentUser =
    user?.result?.googleId === post?.creator ||
    user?.result?._id === post?.creator;
  const [updatePost, { isLoading: isUpdating, error: updateError }] =
    useUpdatePostMutation();

  useEffect(() => {
    console.log(activeSpotToEdit);
    console.log(store.length);
    if (activeSpotToEdit != null && activeSpotToEdit < store.length) {
      setSelectedPano(activeSpotToEdit);
    } else {
      setSelectedPano(0);
    }
  }, [activeSpotToEdit]);

  const [editState, setEditState] = useState({
    isActive: false,
    hotspotActive: false,
    infospotActive: false,
    storeChangesLog: [],
    isHPdeleting: [],
    isISdeleting: [],
  });

  const [panoState, setPanoState] = useState({
    which: 0,
    displaySpots: true,
    storeChangesLog: [],
  });

  function makeSpotActiveToEdit(index) {
    setActiveSpotToEdit(index);
  }

  const toggleClass = async () => {
    checkForEmptySpots("infospotInfo", "");
    checkForEmptySpots("link", "not-linked");

    setEditState({
      ...editState,
      isActive: !editState.isActive,
      hotspotActive: false,
      infospotActive: false,
    });

    if (editState.isActive !== false) {
      await updatePost({ id, changes: { ...post, storeChangesLog } });
    }
  };

  function toggleInfospot() {
    checkForEmptySpots("infospotInfo", "");
    checkForEmptySpots("link", "not-linked");

    setEditState({
      ...editState,
      infospotActive: !editState.infospotActive,
      hotspotActive: false,
    });
  }

  function toggleHotspot() {
    checkForEmptySpots("link", "not-linked");
    checkForEmptySpots("infospotInfo", "");

    setEditState({
      ...editState,
      hotspotActive: !editState.hotspotActive,
      infospotActive: false,
    });
  }

  function checkForEmptySpots(property, emptyValue) {
    setActiveSpotToEdit(null);
    const updatedStore = store.map((entry) => {
      if (entry[property]?.includes(emptyValue)) {
        var idx = entry[property].indexOf(emptyValue);

        while (idx !== -1) {
          if (emptyValue === "") {
            entry.infospot.splice(idx, 1);
            entry.infospotInfo.splice(idx, 1);
            idx = entry.infospotInfo.indexOf("", idx + 1);
          } else if (emptyValue === "not-linked") {
            entry.name.splice(idx, 1);
            entry.position.splice(idx, 1);
            entry.link.splice(idx, 1);
            idx = entry.link.indexOf("not-linked", idx + 1);
          }
        }
      } else if (
        entry.infospot?.length !== entry.infospotInfo?.length ||
        entry.name.length !== entry.position.length ||
        entry.name.length !== entry.link.length ||
        entry.link.length !== entry.position.length
      ) {
        console.log("sum ting wong");
      }
      return entry;
    });

    setStore(updatedStore);
  }

  const videoUrl = post?.store[0].url;

  return (
    <div
      style={{
        position: "relative",
        width: "100%",
        height: "80vh",
        minHeight: "500px",
      }}
    >
      <div ref={panoramaRef} style={{ width: "100%", height: "100%" }}>
        {isCurrentUser && post?.postType !== "single-image" ? (
          <EditControls
            store={store}
            setStoreChangesLog={setStoreChangesLog}
            editState={editState}
            setEditState={setEditState}
            toggleClass={toggleClass}
            toggleHotspot={toggleHotspot}
            toggleInfospot={toggleInfospot}
            post={post}
            which={which}
            setStore={setStore}
            id={id}
            activeSpotToEdit={activeSpotToEdit}
            selectedPano={selectedPano}
            setSelectedPano={setSelectedPano}
          />
        ) : null}
        <ViewControls
          panoramaRef={panoramaRef}
          displaySpots={displaySpots}
          setDisplaySpots={setDisplaySpots}
          post={post}
        />
        <Panorama
          rotate={!displaySpots}
          store={store}
          setStore={setStore}
          post={post}
          id={id}
          storeChangesLog={storeChangesLog}
          setStoreChangesLog={setStoreChangesLog}
          checkForEmptySpots={checkForEmptySpots}
          which={which}
          setWhich={setWhich}
          displaySpots={displaySpots}
          makeSpotActiveToEdit={makeSpotActiveToEdit}
          activeSpotToEdit={activeSpotToEdit}
          editState={editState}
          setActiveSpotToEdit={setActiveSpotToEdit}
          selectedPano={selectedPano}
        />
        {post?.postType !== "single-image" ? (
          <ImgSlideShow store={store} setWhich={setWhich} />
        ) : null}
      </div>
    </div>
  );
};

export default PostContent;
